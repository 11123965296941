import { Global, css, useTheme } from '@emotion/react';
import { globals } from 'common-ui';
import { globalcss } from 'dw-us-npr/theme';

const GlobalStyles = () => {
  const theme = useTheme();

  //NOTE: be VERY careful about setting global styles as this component is injected into
  //an existing page and hence global styles here will potentially affect all page styling
  const globalstyles = globalcss(theme);

  // * Add Css releated to embedded app only.
  // * styles applied here will be injected into
  // * an existing pages and hence embeddedStyles here will potentially have side affect all page styling
  const embeddedStyles = css`
    @font-face {
      font-family: 'Gotham';
      src: url('${globals.assetsPath}/next/fonts/Gotham-Bold.woff2') format('woff2');
      font-weight: 700;
    }
    @font-face {
      font-family: 'Gotham';
      src: url('${globals.assetsPath}/next/fonts/Gotham-Book.woff2') format('woff2');
      font-weight: 400;
    }
    ${globalstyles}

    .vine-react-app {
      font-family: ${theme.fonts.familySecondary};
      font-size: ${theme.fonts.sizeRoot};
    }
    .sticky-header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 600;
    }
    .enabled-vine-header {
      .aa-Panel {
        background: ${theme.colors.white};
        clip-path: inset(0 -100vmax);
        z-index: 999;
        box-shadow: 0 0 0 100vmax ${theme.colors.white};
        margin: 0;
        height: 100%;
        padding: 0 13px 67px;
        width: 100% !important;
        ${theme.breakpoints.lg} {
          height: auto;
          padding: 0;
        }
      }
      .aa-PanelLayout {
        max-height: 100%;
        flex-direction: column;
        ${theme.breakpoints.lg} {
          max-height: initial;
        }
      }
      .aa-SourceHeader {
        border-bottom: 0;
      }
      .npr-homepage-banners-new {
        margin-top: 0;
      }
    }
  `;
  return <Global styles={embeddedStyles} />;
};
export default GlobalStyles;
