import LocalFont from 'next/font/local';

const gothamFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/gotham/Gotham-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/next/fonts/gotham/Gotham-Book.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
});

export { gothamFont };
